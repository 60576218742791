import { Fragment } from "react";
import useObjectState from "../../../../scripts/hooks/use.object.state";
import Textfield from "../../inputs/textfield";
import ActionButtons from "../../inputs/action.buttons";
import useIdFromState from "../../../../scripts/hooks/use.id.from.state";
import Selectfield from "../../inputs/selectfield";
import { ADMINS_ROLES } from "../../../../constants/admins.roles";

const AdminForm = ({ initialData, handleConfirm, handleCancel, handleRemove = false, isAdd }) => {

  const data = { email: initialData?.email || '', password: initialData?.password || '', role: initialData?.role?.name || '' };

  const [ form, setFormField ] = useObjectState(data);

  const [adminRole, setAdminRole] = useIdFromState(data.role, id => setFormField("role", id));

  return (

    <Fragment>

      <h2>{ form?.id ? 'Редактировать' : 'Добавить' } администратора</h2>

      <br/>

      { isAdd &&

        <Textfield

          title = "Email:"
          type = 'email'
          value = { form.email }
          set = { n => setFormField("email", n ) }

        />

      }

      { isAdd &&
      
        <Textfield

          title = "Пароль:"
          type = 'password'
          value = { form.password }
          set = { n => setFormField("password", n ) }

        />

      }

      <Selectfield

        title = 'Роль:'
        list = { ADMINS_ROLES }
        selected = { adminRole }
        select = { setAdminRole }

      />

      <ActionButtons

        confirm = { handleConfirm && ( () => handleConfirm( form ) ) }
        remove = { handleRemove }
        cancel = { handleCancel }

      />

    </Fragment>

  );

}

export default AdminForm;