import { useNavigate } from "react-router-dom";
import useGlobal from "../../../store";
import Carcas from "../../components/containers/carcas";
import DataList from "../../components/ui/data.list";
import DataListControls from "../../components/ui/data.list.controls";
import { useGet } from "../../../scripts/backend/hooks";
import AvatarField from "../../components/ui/data.list/data.list.fields/avatar.field";
import DateField from "../../components/ui/data.list/data.list.fields/date.field";

const News = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { changeStates } = globalActions;

  const navigate = useNavigate();

  const [ data, isLoaded ] = useGet('news');

  function openEditForm( data ) {

    changeStates({ initialData: data });
    navigate(`/news/${ data.url }`);

  }

  return (

    <Carcas>

      <DataListControls />

      { !isLoaded ? <p>Загружаю...</p> :

        <DataList

          source = { data.data }
          onRowClick = { openEditForm }

          widths = "5% 70% 25%"

          names = {[

            "Фото",
            "Название статьи",
            "Дата добавления",

          ]}

          values = {[

            "photo",
            "name",
            "created_at",

          ]}

          containers = {{ 
            
            0: AvatarField,
            2: DateField,

          }}

        />

      }

    </Carcas>
    
  );

}

export default News;
