import Carcas from "../../components/containers/carcas";
import DataList from "../../components/ui/data.list";
import useGlobal from "../../../store";
import { useNavigate } from "react-router-dom";
import { useGet } from "../../../scripts/backend/hooks";
import UrlField from "../../components/ui/data.list/data.list.fields/url.field";
import objectToGetParams from "../../../scripts/helpers/object.to.get.params";
import objectOfGetParamsFromUrl from "../../../scripts/helpers/object.of.get.params.from.url";
import DateFieldWithoutTime from "../../components/ui/data.list/data.list.fields/date.field/without.time";
import DataListControls from "../../components/ui/data.list.controls";

const Admins = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { changeStates } = globalActions;

  const navigate = useNavigate();
  
  const allGetParams = objectOfGetParamsFromUrl();
  const [ data, isLoaded ] = useGet(`admin/user/admin?${ objectToGetParams( allGetParams ) }`);

  console.log('data', data);

  function openEditForm( data ) {

    changeStates({ initialData: data });
    navigate(`/admins/${ data.id }`)

  }

  return (

    <Carcas>

      <DataListControls />

      { !isLoaded ? <p>Загружаю...</p> :

        <DataList

          source = { data }
          onRowClick = { openEditForm }

          widths = "20% 40% 30%"

          names = {[

            "Дата создания",
            "E-mail",
            "Роль",

          ]}

          values = {[

            "created_at",
            "email",
            "role.description",

          ]}

          containers = {{ 
            
            0: DateFieldWithoutTime,
            1: props => <UrlField prefix = "mailto:" { ...props } />,


          }}

        />

      }

    </Carcas>
    
  );

}

export default Admins;
