import Button from "../button";
import "./actions.buttons.scss";

const ActionButtons = ( props ) => {

  const { 
    
    confirm = false, 
    confirmDisabled = false,
    confirmText = false,
    cancelText = false,
    removeText = false,

    cancel, 
    remove = false,
    deactivate = false

  } = props;

  return (

    <div className = "actionbtns flex">

      { confirm &&
      
        <Button 
        
          action = { confirm }
          color = "green"
          disabled = { confirmDisabled }
          
        >
            
          { confirmText || ( remove ? 'Сохранить' : 'Добавить' ) }
        
        </Button>
        
      }
      
      { remove && 
      
        <Button 
        
          action = { remove } 
          color = "red"
          
        >
          
          { removeText || 'Удалить' }

        </Button>
        
      }
      
      <Button 
      
        action = { cancel } 
        color = "gray"
        
      >
        
        { cancelText || ( remove ? 'Назад' : 'Отмена' ) }

      </Button>

      { deactivate &&

        <Button

          action = { deactivate }
          color = "red"

        >

          Деактивировать

        </Button>

      }

    </div>

  );

}

export default ActionButtons;