// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carcas {
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-rows: 1fr;
  flex-grow: 1;
  max-height: 100vh;
}
.carcas .content {
  padding: 18px 6px 6px 14px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
}`, "",{"version":3,"sources":["webpack://./src/react/components/containers/carcas/carcas.scss"],"names":[],"mappings":"AAAA;EAEE,aAAA;EACA,gCAAA;EACA,uBAAA;EACA,YAAA;EACA,iBAAA;AAAF;AAEE;EAEE,0BAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;AADJ","sourcesContent":[".carcas {\n\n  display: grid;\n  grid-template-columns: 300px 1fr;\n  grid-template-rows: 1fr;\n  flex-grow: 1;\n  max-height: 100vh;\n\n  .content {\n\n    padding: 18px 6px 6px 14px;\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n    width: 100%;\n    height: 100%;\n    max-height: 100vh;\n    background: #fff;\n    overflow-y: auto;\n    overflow-x: hidden;\n\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
