import getFromObject from "../../../../scripts/helpers/get.from.object";
import RowsCounterColHead from "./data.list.columns/rows.counter.colhead";
import "./data.list.scss";

const DataList = ( props ) => {

  const {

    source,
    names,
    values,
    containers,
    widths = undefined,
    onRowClick = () => {},

  } = props;

  const gridTemplateColumns = '4vw ' + widths;

  return (

    <div className = "datalist">

      <div 
      
        className = "datalist__row datalist__row--head grid items-start"
        style = {{ gridTemplateColumns }}
        
      >

        <RowsCounterColHead value = { source?.length } />

        { names?.map(( el, key ) => ( 
          
          <span key = { key }>{ el }</span>
      
        ))}
        
      </div>

      <div className = "datalist__content grid">

        { Array.isArray(source) && source?.map(( row, key ) => (

          <div 
          
            key = { key }
            className = "datalist__row grid items-center" 
            onClick = { () => { onRowClick( row ) }}
            style = {{ gridTemplateColumns }}
            
          >

            <div className = "table table__col" key = {`row-${ key + 1 }`}>{ key + 1 }</div>

            { values?.map(( col, index ) => {

              const CustomComponent = containers?.[ index ];
              
              return (

                <div className = "table table__col" key = { index }>

                  { CustomComponent

                    ? <CustomComponent 
                    
                        key = { index }
                        row = { row } 
                        
                      >
                        
                        { getFromObject( col, row ) }

                      </CustomComponent>

                    : getFromObject( col, row )
                  
                  }
                  
                </div>

              )
            
            })}

          </div>

        ))}

      </div>

    </div>

  );

}

export default DataList;