import { useEffect, useState } from "react";
import { DEAL_STATUS_LIST } from "../../../../../constants/deals";
import UTCDate from "../../../../../scripts/helpers/utc.date";
import useObjectState from "../../../../../scripts/hooks/use.object.state";
import ActionButtons from "../../../inputs/action.buttons";
import Selectfield from "../../../inputs/selectfield";
import Textfield from "../../../inputs/textfield";
import "./deal.form.scss";

const DealForm = ( props ) => {

  const {

    initialData = {}, 
    handleConfirm = false, 
    handleCancel = false, 
    handleRemove = false, 
    confirmText = false, 
    cancelText = false, 
    removeText = false 

  } = props;

  const [ form, setFormField ] = useObjectState( initialData );
  const [ isValid, setIsValid ] = useState( true );

  console.log('form', form);

  const {

    title,
    deal_num,
    deal_sum,
    created_at,
    comment,
    status,
    configuration_link,
    competition_link,
    partner,
    customer,
    distributor,
    special_discount,
    discount_date,
    purchase_date

  } = form;

  useEffect(() => {

    special_discount?.includes('%')
      ? parseInt(special_discount) > 100
        ? setIsValid( false )
        : setIsValid( true )
      : setIsValid( true );

  }, [ form ]);

  useEffect(() => {

    setFormField( "discount_date", !discount_date ? undefined : UTCDate( discount_date ) );

  }, [ title ]);

  return (

    <div className = "deal_form">

      <div className = "grid">

        <div>

          <Textfield title = "Номер сделки" value = { deal_num } className = "events-none" editable = { false } />
          <Textfield title = "Название сделки" value = { title } className = "events-none" editable = { false } />
          <Textfield title = "Сумма сделки" value = { deal_sum } className = "events-none" editable = { false } />
          <Textfield title = "Комментарий" value = { comment } className = "events-none" editable = { false } />
          <Textfield title = "Компания партнёра" value = { partner?.owner_company?.name?.length ? partner.owner_company.name : 'не указана' } className = "events-none" editable = { false } />
          <Textfield title = "Ссылка на конфигурацию" value = { configuration_link && configuration_link?.includes('http') ? configuration_link : ("https://" + configuration_link) } editable = { false } isLink />

          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <hr/>
          <br/>

          <Textfield 

            title = "Введите значение партнёрской скидки:" 
            value = { special_discount } 
            placeholder = "Оставить пустым, если не надо выдавать скидку" 
            set = { n => setFormField("special_discount", n ) }
            bigTitle
            error = { isValid ? "" : "Значение скидки не может превышать 100%" }

          />

          <br/>

          <Textfield 

            title = "Введите срок действия партнёрской скидки:" 
            value = { discount_date } 
            placeholder = "Пусто, если нет скидки или ограничения времени" 
            set = { n => setFormField("discount_date", n ) }
            bigTitle

          />

          <br/>

          <Selectfield

            title = "Выберите статус сделки"
            list = { DEAL_STATUS_LIST }
            select = { n => setFormField("status", n.id ) }
            selected = { DEAL_STATUS_LIST.find( el => el.id === status )?.name }
            bigTitle

          />
              
        </div>

        <div>

          <Textfield title = "Компания заказчика" value = { customer?.company_name } className = "events-none" editable = { false } />
          <Textfield title = "ИНН компании заказчика" value = { customer?.inn } className = "events-none" editable = { false } /> 
          <Textfield title = "Фамилия заказчика" value = { customer?.last_name } className = "events-none" editable = { false } />
          <Textfield title = "Имя заказчика" value = { customer?.first_name } className = "events-none" editable = { false } />
          <Textfield title = "ИНН партнёра" value = { partner?.owner_company?.inn || 'не указан' } className = "events-none" editable = { false } />
          <Textfield title = "Ссылка на конкурс" value = { competition_link } className = "events-none" editable = { false } />

          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <hr/>
          <br/>

        </div>

        <div>

          <Textfield title = "Телефон заказчика" value = { customer?.phone } className = "events-none" editable = { false } />
          <Textfield title = "E-mail заказчика" value = { customer?.email } className = "events-none" editable = { false } />
          <Textfield title = "Дистрибьютор" value = { distributor?.name } className = "events-none" editable = { false } />
          <Textfield title = "E-mail партнёра" value = { partner?.email?.length ? partner.email : 'не указан' } className = "events-none" editable = { false } />
          <Textfield title = "Телефон партнёра" value = { partner?.phone?.length ? partner.phone : 'не указан' } className = "events-none" editable = { false } />
          <Textfield title = "Дата сделки" value = { UTCDate( created_at, false ) } className = "events-none" editable = { false } />
          <Textfield title = "Дата закупки" value = { UTCDate( purchase_date, false ) } className = "events-none margin-small" editable = { false } />

          <br/>
          <hr/>
          <br/>

        </div>

      </div>

      <ActionButtons

        confirm = { handleConfirm && isValid && ( () => handleConfirm( form ) ) }
        confirmText = { confirmText }
        cancelText = { cancelText }
        removeText = { removeText }
        remove = { handleRemove }
        cancel = { handleCancel }

      />

    </div>

  );

}

export default DealForm;