import { useEffect, useState } from "react";
import Textfield from "../../components/inputs/textfield";
import { useNavigate } from "react-router-dom";
import useGlobal from "../../../store";
import Button from "../../components/inputs/button";

const AuthPage = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { user_role } = globalState;
  const { sign, changeStates } = globalActions;

  const [ email, setEmail ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ error, setError ] = useState("");

  const isFormValidated = ( email.indexOf('@') !== -1 && email.indexOf('.') !== -1 && password.length > 0 );

  const navigate = useNavigate();

  async function checkRole() {

    const data = await sign.CheckRole();
    console.log('checkRole auth data', data);

  }

  const redirect = () => {
  
    console.log('redirect called');
    checkRole();

  }

  async function handleSignIn() {
    
    return await sign.In({ 
      
      email, 
      password, 
      onSuccess: redirect,
      onError: setError
    
    });
  
  }

  useEffect(() => { 
    
    sign.Logout(); 
  
  }, []);

  useEffect(() => {

    !user_role 
      ? navigate('/auth')
      : user_role === 'super_admin' 
        ? navigate('/partners')
        : user_role === 'content_manager'
          ? navigate('/news')
          : navigate('/auth');

  }, [ user_role ]);

  useEffect(() => { 
    
    error !== "" && setError("") 
  
  }, [ email, password ]);

  return (

    <div className = "flex-1 grid justify-center items-center">

      <div>

        <h2>Авторизация</h2>
        <br/>

        <Textfield

          title = "e-mail"
          value = { email }
          set = { setEmail }

        />

        <Textfield

          title = "пароль"
          type = "password"
          value = { password }
          set = { setPassword }

        />

        { error !== "" && <p className = "error">{ error }</p> }

        <Button 
        
          action = { handleSignIn }
          disabled = { !isFormValidated }
          
        >

          Войти

        </Button>

      </div>

    </div>

  );

}

export default AuthPage;