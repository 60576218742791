import { createBrowserRouter } from "react-router-dom";
import AuthPage from './auth';
import Partners from "./users/partners";
import Servers from "./configurator/servers";
import Components from "./configurator/components";
import Serverboxes from "./configurator/serverboxes";
import ServerAdd from "./configurator/servers/add";
import Slots from "./configurator/slots";
import SlotAdd from "./configurator/slots/add";
import SlotEdit from "./configurator/slots/edit";
import ComponentAdd from "./configurator/components/add";
import MultislotAdd from "./configurator/multislots/add";
import Multislots from "./configurator/multislots";
import ComponentEdit from "./configurator/components/edit";
import MultislotEdit from "./configurator/multislots/edit";
import ServerEdit from "./configurator/servers/edit";
import ServerboxAdd from "./configurator/serverboxes/add";
import ServerboxEdit from "./configurator/serverboxes/edit";
import PartnerRequests from "./users/partners-requests";
import PartnerRequestView from "./users/partners-requests/view";
import PartnerEdit from "./users/partners/edit";
import Deals from "./deals";
import DealView from "./deals/view";
import ServerGenerations from "./configurator/server-generations";
import ServerGenerationAdd from "./configurator/server-generations/add";
import ServerGenerationEdit from "./configurator/server-generations/edit";
import CpuGenerationAdd from "./configurator/cpu-generations/add";
import CpuGenerations from "./configurator/cpu-generations";
import CpuGenerationEdit from "./configurator/cpu-generations/edit";
import Distributors from "./distributors";
import DistributorEdit from "./distributors/edit";
import DistributorAdd from "./distributors/add";
import News from "./news";
import NewsEdit from "./news/edit";
import NewsAdd from "./news/add";
import PartnersRejected from "./users/partners-rejected";
import PartnerRejectedView from "./users/partners-rejected/view";
import Admins from "./admins";
import AdminEdit from "./admins/edit";
import AdminAdd from "./admins/add";


const router = createBrowserRouter([

  { path: "/",                element: <AuthPage />       },

  { path: "/auth",            element: <AuthPage />       },

  { path: "/admins",           element: <Admins/>           },
  { path: "/admins/add",       element: <AdminAdd/>        },
  { path: "/admins/:id",       element: <AdminEdit/>        },

  { path: "/partners",             element: <Partners />           },
  { path: "/partners/:id",         element: <PartnerEdit />        },
  { path: "/partner-requests",     element: <PartnerRequests />    },
  { path: "/partner-requests/:id", element: <PartnerRequestView /> },
  { path: "/partners-rejected",     element: <PartnersRejected />    },
  { path: "/partners-rejected/:id", element: <PartnerRejectedView /> },

  { path: "/serverboxes",     element: <Serverboxes/>     },
  { path: "/serverboxes/add", element: <ServerboxAdd/>    },
  { path: "/serverboxes/:id", element: <ServerboxEdit/>   },

  { path: "/servers",         element: <Servers/>         },
  { path: "/servers/add",     element: <ServerAdd />      },
  { path: "/servers/:id",     element: <ServerEdit />     },

  { path: "/slots",           element: <Slots />          },
  { path: "/slots/add",       element: <SlotAdd />        },
  { path: "/slots/:id",       element: <SlotEdit />       },

  { path: "/multislots",      element: <Multislots/>      },
  { path: "/multislots/add",  element: <MultislotAdd />   },
  { path: "/multislots/:id",  element: <MultislotEdit />  },

  { path: "/components",      element: <Components/>      },
  { path: "/components/add",  element: <ComponentAdd/>    },
  { path: "/components/:id",  element: <ComponentEdit/>   },

  { path: "/server-generations",      element: <ServerGenerations />    },
  { path: "/server-generations/add",  element: <ServerGenerationAdd />  },
  { path: "/server-generations/:id",  element: <ServerGenerationEdit /> },
  
  { path: "/cpu-generations",         element: <CpuGenerations />    },
  { path: "/cpu-generations/add",     element: <CpuGenerationAdd />  },
  { path: "/cpu-generations/:id",     element: <CpuGenerationEdit /> },

  { path: "/deals",           element: <Deals/>           },
  { path: "/deals/:id",       element: <DealView/>        },

  { path: "/distributors",      element: <Distributors />    },
  { path: "/distributors/add",  element: <DistributorAdd />  },
  { path: "/distributors/:id",  element: <DistributorEdit /> },

  { path: "/news",           element: <News/>           },
  { path: "/news/add",  element: <NewsAdd />            },
  { path: "/news/:id",  element: <NewsEdit />           },
	
]);

export default router;