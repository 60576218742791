import useGoBack from "../../../scripts/hooks/use.go.back";
import Carcas from "../../components/containers/carcas";
import backend from "../../../scripts/backend";
import { useParams } from "react-router-dom";
import API from "../../../constants/api";
import useGlobal from "../../../store";
import { useEffect } from "react";
import AdminForm from "../../components/forms/admins";

const AdminEdit = () => {

  const params = useParams();
  const { id } = params;
  const goBack = useGoBack();

  const [ globalState, globalActions ] = useGlobal();
  const { initialData } = globalState;
  const { changeStates } = globalActions;


  useEffect(() => {

    !initialData?.id && goBack();
    return () => { changeStates({ initialData: {} }) }

  }, []);

  const handleEdit = (form) => {
    
    backend.edit( API.admin.edit( id ), form, goBack );
  
  }

  const handleRemove = () => {
    
    if ( !window.confirm('Точно хотите удалить?') ) return;
    backend.remove( API.admin.remove( id ), goBack );
  
  }

  return (

    <Carcas>

      <AdminForm 
      
        initialData   = { initialData }
        handleConfirm = { handleEdit }
        handleRemove  = { handleRemove }
        handleCancel  = { goBack }
        isAdd = { false }
        
      />

    </Carcas>

  );

}

export default AdminEdit;