import "./rows.counter.colhead.scss";

const RowsCounterColHead = ({ value = 0 }) => {

  return (

    <span className = "rowsCounterColHead flex flex-col relative items-center" title = "Найдено записей">

      <div className = "rowsCounterColHead__text">кол-во</div>
      <div className = "rowsCounterColHead__value" >{ value }</div>

    </span>

  );

}

export default RowsCounterColHead;