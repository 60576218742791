const DEAL_STATUS_NAME = {
  
  'moderation':	'на рассмотрении',
  'registered':	'зарегистрирована',
  'canceled':	  'отклонена',
  'win':	      'победа',
  'loose':	    'поражение',
  
}

export default DEAL_STATUS_NAME;