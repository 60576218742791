import Carcas from "../../components/containers/carcas";
import backend from "../../../scripts/backend";
import API from "../../../constants/api";
import useGoBack from "../../../scripts/hooks/use.go.back";
import { useParams } from "react-router-dom";
import useGlobal from "../../../store";
import { useEffect, useState } from "react";
import NewsForm from "../../components/forms/news";

const NewsEdit = () => {

  const params = useParams();
  const { id } = params;
  const goBack = useGoBack();

  const [ globalState, globalActions ] = useGlobal();
  const { initialData } = globalState;
  const { changeStates } = globalActions;

  const [ isLoaded, setIsLoaded ] = useState( false );

  async function getNew() {

    const result = await backend.get( `news/${ id }` );
    changeStates({ initialData: result });
    setIsLoaded( true );

  }

  useEffect(() => {

    getNew();

    return () => { changeStates({ initialData: {} }) };

  }, []);

  const handleEdit = ( form ) => {
    
    backend.edit( API.news.edit( id ), form, goBack );
  
  }

  const handleRemove = () => {
    
    if ( !window.confirm('Точно хотите удалить?') ) return;
    backend.remove( API.news.remove( id ), goBack, false );
  
  }

  return (

    <Carcas>

      { !isLoaded ? 'Загружаю...' : 
      
        <NewsForm
        
          initialData   = { initialData }
          handleConfirm = { handleEdit }
          handleRemove  = { handleRemove }
          handleCancel  = { goBack }
          
        />
        
      }

    </Carcas>

  );

}

export default NewsEdit;