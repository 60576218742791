import { useEffect, useRef } from "react";
import EditorJS from "@editorjs/editorjs";
import editorLocale from "./locale";
import editorTools from "./tools";
import "./editor.scss";
import useReloadDummy from "../../../../scripts/hooks/use.reload.dummy";

const Editor = ({ title, content, set }) => {

  const editorInstance = useRef(null);
  const isEditorInitialized = useRef(false);

  const [ Dummy, reloadDummy ] = useReloadDummy();

  useEffect(() => {
    
    if ( !editorInstance.current ) {

      editorInstance.current = new EditorJS({
          
        holder: "editorjs",
        data: content?.length ? JSON.parse( content ) : undefined,
        
        tools: editorTools,

        onReady: () => { isEditorInitialized.current = true; },
        onError: (error) => { console.error("Ошибка редактора:", error); },

        onChange: async () => {

          const content = await editorInstance.current.save();
          set( content );

        },

        i18n: editorLocale,

      });

      reloadDummy();

    }
 
    return () => {

      if ( editorInstance.current ) { editorInstance.current = null; }

    };

  }, []);

  return (

    <div className = "editor">

      <Dummy />

      <h3 className = "editor__title">{ title }</h3>

      <div id = "editorjs" className = "editor__text" />

    </div>

  );

}

export default Editor;