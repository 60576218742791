import { Link, useNavigate } from "react-router-dom";
import cssIf from "../../../../scripts/helpers/class.add.if";
import "./navigation.scss";
import useGlobal from "../../../../store";
import { useEffect, useMemo } from "react";

const Navigation = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { user_role } = globalState;
  const { sign } = globalActions;

  const navigate = useNavigate();

  const isActive = ( url ) => {

    const { href, origin } = window.location;
    return href.replace(origin, '') === url;

  }

  useEffect(() => { 
      
    async function checkRole() {

      const data = await sign.CheckRole();
      console.log('checkRole navigation data', data);

    }

    checkRole();

  }, []);
  
  const MENU = useMemo(() => {

    return user_role === 'content_manager'

      ? [
          {
            title: "Новости",
            items: [
              { text: "Все новости", url: "/news" },
            ],
          },
        ]

      : [
          {
            title: "Администраторы",
            items: [
              { text: "Все", url: "/admins?role=all" },
              { text: "Супер-админы", url: "/admins?role=super_admin" },
              { text: "Модераторы", url: "/admins?role=content_manager" },
            ],
          },
          {
            title: "Пользователи",
            items: [
              { text: "Партнёры (заявки)", url: "/partner-requests" },
              { text: "Партнёры", url: "/partners" },
              { text: "Отклонённые", url: "/partners-rejected" },
            ],
          },
          {
            title: "Конфигуратор",
            items: [
              { text: "Корпуса", url: "/serverboxes" },
              { text: "Слоты", url: "/slots" },
              { text: "Поколения серверов", url: "/server-generations" },
              { text: "Сервера", url: "/servers" },
              { text: "Поколения процессоров", url: "/cpu-generations" },
              { text: "Компоненты", url: "/components" },
            ],
          },
          {
            title: "Сделки",
            items: [
              { text: "Дистрибьюторы", url: "/distributors" },
              { text: "Все сделки", url: "/deals?all" },
              { text: "Запросы на регистрацию", url: "/deals?status=moderation" },
              { text: "Зарегистрированные", url: "/deals?status=registered" },
              { text: "Не зарегистрированные", url: "/deals?status=canceled" },
              { text: "Победные", url: "/deals?status=win" },
              { text: "Проигранные", url: "/deals?status=loose" },
            ],
          },
          {
            title: "Новости",
            items: [
              { text: "Все новости", url: "/news" },
            ],
          },
        ];

  }, [ user_role ]);

  useEffect(() => {

    const currentPath = window.location.pathname;
    const isOnNewsPage = currentPath.startsWith('/news');

    if ( user_role === 'content_manager' && !isOnNewsPage ) { navigate('/news'); }

  }, [ user_role ]);

  return (

    <nav className = "menu">

      <div className = "logo">

        <Link to = "/"> Тринити</Link>

      </div> 

      { MENU.map(( section, sectionIndex ) => (

        <div 
        
          key = { sectionIndex } 
          className = "menu__section"
          
        > 

          <div className = "menu__title">

            { section.title }
            
          </div>

          { section.items.map(( item, key ) => (

            <NavElement

              key = { key }
              url = { item.url }
              text = { item.text }
              isActive = { isActive( item.url ) }
            
            />

          ))}

        </div>

      ))}

    </nav>

  );

}

const NavElement = ({ text, url, isActive = false }) => (

  <Link

    to = { url }
    className = {`menu__link ${ cssIf( isActive, "menu__link--active" ) }`}

  >

    { text }

  </Link>

);

export default Navigation;