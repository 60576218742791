import cookie from 'react-cookies';
import API from '../../constants/api';
import {http} from "../../scripts/backend/http";
import headersWithToken from '../../scripts/helpers/headers.token';

export async function In( store, { email, password, code = undefined, onSuccess = () => {}, onError = () => {} }) {

  try {

    const { data } = await http.post( API.sign.in, { email, password, code } );

    console.log(`signIn data.token:`, data?.token);

    // if ( !data?.token ) { throw('Неправильные данные'); }

    cookie.save('token', data.token, { path: '/' });
    console.log(`signIn data.token cookie saved:`, data?.token);

    onSuccess();

  } catch ( err ) {

    console.error( err );
    onError( err?.message || "Не удалось войти" );

  }
  
}

export async function CheckRole( store ) {
  
  try {

    const headers = headersWithToken();
    const { data } = await http.get( API.sign.check, { headers } );

    if ( !data.id ) { throw( data.error ); }

    store.setState({ 

      user_role: data?.role?.name || '',

    });

    return data;

  } catch ( err ) {

    return err;    

  }
  
}

export function Logout( store ) {

  cookie.remove('token', { path: '/' });
  console.log('Токен удален');

  store.setState({ user_role: '' });

}