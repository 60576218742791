const DeleteIcon = ( props ) => {

  return (

    <svg 
    
      width="15" 
      height="15" 
      viewBox="0 0 15 15" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      { ...props }
      
    >

      <path 
      
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M0.000117029 1.6073L1.60742 0L7.49994 5.89252L13.3923 0.000120145L14.9996 1.60742L9.10724 7.49982L14.9999 13.3924L13.3926 14.9997L7.49994 9.10712L1.60719 14.9999L-0.000108731 13.3926L5.89264 7.49982L0.000117029 1.6073Z" 
        fill="#ED6957"
        
      />
    
    </svg>

  );

};

export default DeleteIcon;