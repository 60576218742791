// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rowsCounterColHead {
  min-width: 70px;
  left: -28px;
}
.rowsCounterColHead div {
  position: relative;
}
.rowsCounterColHead__text {
  top: -2px;
  font-size: 12px;
}
.rowsCounterColHead__value {
  top: -3px;
  left: -3px;
  font-size: 14px;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/react/components/ui/data.list/data.list.columns/rows.counter.colhead/rows.counter.colhead.scss"],"names":[],"mappings":"AAAA;EAEE,eAAA;EACA,WAAA;AAAF;AAEE;EAAM,kBAAA;AACR;AACE;EAEE,SAAA;EACA,eAAA;AAAJ;AAIE;EAEE,SAAA;EACA,UAAA;EACA,eAAA;EACA,gBAAA;AAHJ","sourcesContent":[".rowsCounterColHead {\n\n  min-width: 70px;\n  left: -28px;\n\n  div { position: relative; }\n\n  &__text {\n\n    top: -2px;\n    font-size: 12px;\n\n  }\n\n  &__value {\n    \n    top: -3px;\n    left: -3px;\n    font-size: 14px;\n    font-weight: 700;\n\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
