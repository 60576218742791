import Carcas from "../../../components/containers/carcas";
import DataList from "../../../components/ui/data.list";
import DataListControls from "../../../components/ui/data.list.controls";
import { useGet } from "../../../../scripts/backend/hooks";
import useGlobal from "../../../../store";
import { useNavigate } from "react-router-dom";
import PriceField from "../../../components/ui/data.list/data.list.fields/price.field";
import SlotField from "../../../components/ui/data.list/data.list.fields/slot.field";
import objectOfGetParamsFromUrl from "../../../../scripts/helpers/object.of.get.params.from.url";
import objectToGetParams from "../../../../scripts/helpers/object.to.get.params";
import ComponentTypeField from "../../../components/ui/data.list/data.list.fields/component.type.field";
import SelectFromBackend from "../../../components/inputs/select.from.backend";
import { useState } from "react";

const Components = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { changeStates } = globalActions;

  const navigate = useNavigate();

  const allGetParams = objectOfGetParamsFromUrl();
  const [ data, isLoaded ] = useGet(`configurator/component?${ objectToGetParams( allGetParams ) }`);

  function openEditForm( data ) {

    changeStates({ initialData: data });
    navigate(`/components/${ data.id }`)

  }

  const [ componentType, setComponentType ] = useState( allGetParams?.componentType );

  function handleFilerByComponentType( cType = false ) {

    const cTypeId = cType?.id;
    !componentType?.id && setComponentType( cType );

    if ( componentType?.id || !allGetParams?.componentType ) {

      let url = `/components`;
      
      if ( cTypeId ) {

        url +=`?componentType=${ cTypeId }`;
        localStorage.setItem('componentType', cTypeId );

      } else {

        localStorage.removeItem('componentType');

      }
      
      window.location = url;

    }
    
  }

  return (

    <Carcas>

      <DataListControls>

        <SelectFromBackend

          title = 'Тип компонента'
          source = 'configurator/componentType'
          selected = { componentType }
          select = { handleFilerByComponentType }
          canBeNull

        />

      </DataListControls>

      { !isLoaded ? <p>Загружаю...</p> :
        
        <DataList

          source = { data }
          widths = "15% 1fr 13% 25%"
          names = {[ 
            "Тип", 
            "Название", 
            "Цена", 
            "Влияние на слоты", 
          ]}
          values = {[ 
            "type_id",
            "name",
            "price",
            "slots",
          ]}

          containers = {{ 

            0: ComponentTypeField,
            2: PriceField,
            3: SlotField,
  
          }}

          onRowClick = { openEditForm }

        />
      
      }

    </Carcas>
    
  );

}

export default Components;
