import { useEffect } from "react";
import API from "../../../../constants/api";
import backend from "../../../../scripts/backend";
import useGoBack from "../../../../scripts/hooks/use.go.back";
import Carcas from "../../../components/containers/carcas";
import useGlobal from "../../../../store";
import { useParams } from "react-router-dom";
import PartnerForm from "../../../components/forms/users/partner.form";

const PartnerEdit = () => {

  const params = useParams();
  const { id } = params;
  const goBack = useGoBack();

  const [ globalState, globalActions ] = useGlobal();
  const { initialData } = globalState;
  const { changeStates } = globalActions;


  useEffect(() => {

    !initialData?.id && goBack();
    return () => { changeStates({ initialData: {} }) }

  }, []);

  const handleReject = () => {

    if (!window.confirm('Точно хотите удалить?')) return;
    backend.remove(API.user.partner.request.reject(id), goBack);

  }

  return (

    <Carcas>

      <PartnerForm

        initialData={initialData}
        cancelText="Назад"
        handleCancel={goBack}
        handleDeactivate = { handleReject }

      />

    </Carcas>

  );

}

export default PartnerEdit;