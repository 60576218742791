import DealForm from "../../components/forms/deals/deal.form";
import useGoBack from "../../../scripts/hooks/use.go.back";
import Carcas from "../../components/containers/carcas";
import backend from "../../../scripts/backend";
import { useParams } from "react-router-dom";
import API from "../../../constants/api";
import useGlobal from "../../../store";
import { useEffect } from "react";

const DealView = () => {

  const params = useParams();
  const { id } = params;
  const goBack = useGoBack();

  const [ globalState, globalActions ] = useGlobal();
  const { initialData } = globalState;
  const { changeStates } = globalActions;


  useEffect(() => {

    !initialData?.id && goBack();
    return () => { changeStates({ initialData: {} }) }

  }, []);

  const handleAccept = ({ status, discount_date, special_discount }) => {

    const parts = discount_date?.split('.');
    const day = parseInt(parts?.[0], 10);
    const month = parseInt(parts?.[1], 10) - 1; 
    const year = parseInt(parts?.[2], 10);
    const utcDate = new Date(Date.UTC(year, month, day, 0, 0, 0));

    const params = {

      status,
      discount_date: discount_date ? new Date(utcDate) : null,
      special_discount

    }
    
    backend.patch( API.deal.save( id ), params, goBack );
  
  }

  return (

    <Carcas>

      <DealForm 
      
        initialData   = { initialData } 

        handleConfirm = { handleAccept }
        handleCancel  = { goBack }

        confirmText   = { "Сохранить изменения" }
        removeText    = { "Отклонить сделку" }
        
      />

    </Carcas>

  );

}

export default DealView;