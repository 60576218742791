// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datalist {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow-x: auto;
}
.datalist__row {
  padding: 0px 12px;
  border-radius: 6px;
}
.datalist__row--head {
  position: sticky;
  top: 0;
  right: 0;
  min-height: 34px;
  background: #fff;
  padding-top: unset;
  padding-bottom: unset;
}
.datalist__row--head span {
  width: 100%;
  height: 100%;
  color: #494949;
  font-weight: 500;
  background: #fff;
  border-bottom: 1px solid rgb(238, 238, 238);
}
.datalist__row:nth-child(2n) {
  background-color: #FAFAFA;
}
.datalist__row:nth-child(2n) .table__col {
  background-color: #FAFAFA;
}
.datalist__row .table__col {
  padding: 8px 0px;
  padding-right: 10px;
  -webkit-user-select: none;
          user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/react/components/ui/data.list/data.list.scss"],"names":[],"mappings":"AAAA;EAEE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;EACA,gBAAA;AAAF;AAEE;EAEE,iBAAA;EACA,kBAAA;AADJ;AAGI;EAEE,gBAAA;EACA,MAAA;EACA,QAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,qBAAA;AAFN;AAIM;EAEE,WAAA;EACA,YAAA;EACA,cAAA;EACA,gBAAA;EACA,gBAAA;EACA,2CAAA;AAHR;AASI;EAEE,yBAAA;AARN;AAUQ;EAAS,yBAAA;AAPjB;AAgBM;EAEE,gBAAA;EACA,mBAAA;EACA,yBAAA;UAAA,iBAAA;AAfR","sourcesContent":[".datalist {\n\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  min-height: 0;\n  overflow-x: auto;\n\n  &__row {\n\n    padding: 0px 12px;\n    border-radius: 6px;\n\n    &--head {\n      \n      position: sticky;\n      top: 0;\n      right: 0;\n      min-height: 34px;\n      background: #fff;\n      padding-top: unset;\n      padding-bottom: unset;\n\n      span {\n\n        width: 100%;\n        height: 100%;\n        color: #494949;\n        font-weight: 500;\n        background: #fff;\n        border-bottom: 1px solid rgb(238, 238, 238);\n\n      }\n      \n    }\n\n    &:nth-child(2n) {\n\n      background-color: #FAFAFA;.table {\n\n        &__col { background-color: #FAFAFA; }\n  \n      }\n\n\n    }\n\n    .table {\n\n      &__col {\n\n        padding: 8px 0px;\n        padding-right: 10px;\n        user-select: none;\n\n      }\n\n    }\n\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
