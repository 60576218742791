import React, { Fragment } from 'react';
import "./textfield.scss";
import cssIf from '../../../../scripts/helpers/class.add.if';

const Textfield = ( props ) => {
  
  const { 

    value, 
    set = () => {}, 
    title = "", 
    type = "text", 
    placeholder = "", 
    className = "",
    inputClassName = "", 
    refDOM = false, 
    onBlur = () => {}, 
    onKeyUp = () => {},
    icon = <Fragment />,
    editable = true,
    isLink = false,
    bigTitle = false,
    error = ""

  } = props;

  return (

    <div className = {`textfield ${ className }`}>

      { title !== "" && <div className = {`textfield__title ${ cssIf( bigTitle, "textfield__bigTitle" ) }`}>{ title }</div> }

      <div className = "relative">

        { !isLink ?
          
            <input

              type = { type !== 'number' ? type : 'text' }
              value = { value }
              placeholder = { placeholder }
              className = {` ${ inputClassName } ${ cssIf( !editable, 'non_editable_input' ) }`}
              onChange = { ( event ) => { 
              
                const updValue = type !== 'number' ? event.target.value : event.target.value.replace(',','.').replace(/[^\d.,]/g, '');
                console.log({
                  value,
                  ETvalue: event.target.value,
                  updValue
                })
                set( updValue ) 
              
              }}
              onBlur = { onBlur }
              onKeyUp = { onKeyUp }
              ref = { refDOM ? refDOM : null }

            />

            :

            <a 
            
              href = { value }
              target = "_blank"
              rel = "noreferrer"
              className = {` ${ inputClassName } ${ cssIf( !editable, 'non_editable_input' ) }`}
            
            >
              
              { value }
              
            </a>

        }

        { icon }

        { error !== "" && <div className = 'textfield__error'>{ error }</div> }

      </div>

    </div>

  );

}

export default Textfield;