import Carcas from "../../../components/containers/carcas";
import DataList from "../../../components/ui/data.list";
import useGlobal from "../../../../store";
import { useNavigate } from "react-router-dom";
import { useGet } from "../../../../scripts/backend/hooks";
import UrlField from "../../../components/ui/data.list/data.list.fields/url.field";
import DateFieldWithoutTime from "../../../components/ui/data.list/data.list.fields/date.field/without.time";

const PartnerRequests = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { changeStates } = globalActions;

  const navigate = useNavigate();

  const [ data, isLoaded ] = useGet('admin/partner?status=pending');

  function openEditForm( data ) {

    changeStates({ initialData: data });
    navigate(`/partner-requests/${ data.id }`)

  }

  return (

    <Carcas>

      { !isLoaded ? <p>Загружаю...</p> :

        <DataList

          source = { data }
          onRowClick = { openEditForm }

          widths = "25% 15% 25% 25% 15% 18% 15% 20% 18%"

          names = {[

            "Компания",
            "ИНН",
            "Сайт",
            "E-mail",
            "Телефон",
            "Фамилия",
            "Имя",
            "Должность",
            "Дата создания заявки",

          ]}

          values = {[

            "owner.info.company_name",
            "inn",
            "site_url",
            "owner.email",
            "owner.phone",
            "owner.info.last_name",
            "owner.info.first_name",
            "owner.info.job_title",
            "created_at",

          ]}

          containers = {{ 
            
            2: UrlField,
            3: props => <UrlField prefix = "mailto:" { ...props } />,
            4: props => <UrlField prefix = "tel:" { ...props } />,
            8: DateFieldWithoutTime,

          }}

        />

      }

    </Carcas>
    
  );

}

export default PartnerRequests;
