import API from "../../../../constants/api";
import backend from "../../../../scripts/backend";

const editorTools = {

  header: { 

    class: require("@editorjs/header"), 
    inlineToolbar: true, 
    

  },

  paragraph: { 

    class: require("@editorjs/paragraph"), 
    inlineToolbar: true, 
    config: {
      placeholder: 'Начните писать...'
    }

  },

  image: {

    class: require("@editorjs/image"),

    config: {

      uploader: {
        
        uploadByFile: async ( file ) => {
         
          const response = await backend.postFormData( API.file.upload, file );
          let url = response?.configuration_link; 
          if ( !url.includes('http') ) { url = `https://${ url }`; }

          return {
            
            success: 1,
            file: { url }

          };

        },

      }

    }

  },

  list: { 

    class: require("@editorjs/list"), 
    inlineToolbar: true, 
    

  },
  
}

export default editorTools;