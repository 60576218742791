import { useEffect, useState } from "react";

const UrlField = ({ children, prefix = '' }) => {

  const [ newLinkPrefix, setNewLinkPrefix ] = useState( prefix );

  useEffect(() => {

    if ( prefix === "https://" ) {

      setNewLinkPrefix( children.includes("http") ? "" : "https://" );

    } else {

      setNewLinkPrefix(prefix);

    }

  }, [ children, prefix ]);

  return (

    <a href={`${ newLinkPrefix }${ children }`} target="_blank" rel="noreferrer">{ children }</a>

  )

}

export default UrlField;