import API from "../../../constants/api";
import backend from "../../../scripts/backend";
import useGoBack from "../../../scripts/hooks/use.go.back";
import Carcas from "../../components/containers/carcas";
import AdminForm from "../../components/forms/admins";

const AdminAdd = () => {

  const goBack = useGoBack();

  function handleAdd( form ) {

    backend.add( API.admin.add, form, goBack );

  }

  return (

    <Carcas>

      <AdminForm
      
        handleConfirm = { handleAdd }
        handleCancel  = { goBack } 
        isAdd = { true }
        
      />

    </Carcas>

  );

}

export default AdminAdd;