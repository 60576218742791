import GlobalHook from "use-global-hook";
import * as actions from "./actions";

export const initialPopupStates = {

  popup_opened:           false,
  popup_animate_close:    false,
  popup_confirm_title:    null,
  popup_confirm_text:     null,
  popup_confirm_icon:     null,
  popup_confirm_function: null,

}

export const initialConfiguratorStates = {

  serverboxes: [],
  serverboxesIsLoaded: false,

  servers: [],
  serversIsLoaded: false,
  
  components: [],
  componentsIsLoaded: false,

  slots: [],
  slotsIsLoaded: false,

  multislots: [],
  multislotsIsLoaded: false,

}

const initialState = {


  ...initialPopupStates,
  
  user_role: '',
  dummy: 0,

};

const useGlobal = GlobalHook( initialState, actions );

export default useGlobal;
