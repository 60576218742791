import twoDigitFormat from "../../../../../../scripts/helpers/two.digit.format"

const months = [
  "января",
  "февраля",
  "марта",
  "апреля",
  "мая",
  "июня",
  "июля",
  "августа",
  "сентября",
  "октября",
  "ноября",
  "декабря",
]

const DateField = ({ children }) => {

  const d = new Date( children )
  if ( !d?.getDate() ) { return <span>Некорректная дата</span> }

  let time = ""
  const withoutTime = ( !d?.getHours() && +d.getHours() !== 0 );

  if ( !withoutTime ) {
    time = ` в ${ twoDigitFormat(d.getHours()) }:${ twoDigitFormat(d.getMinutes()) }`;
  }

  const date = `${ d.getUTCDate() } ${ months[ d.getMonth()] } ${ d.getFullYear() }`;
  
  return (

    <span>{ date }{ time }</span>

  );

}

export default DateField;
