import { Fragment, useState } from "react";
import useObjectState from "../../../../scripts/hooks/use.object.state";
import Textfield from "../../../components/inputs/textfield";
import ActionButtons from "../../inputs/action.buttons";
import Filefield from "../../inputs/filefield";
import Editor from "../../inputs/editor";

const NewsForm = ( props ) => {

  const { 

    initialData, 
    handleConfirm, 
    handleCancel, 
    handleRemove = false,

  } = props; 

  const [ form, setFormField ] = useObjectState( initialData );
  const [ content, setContent ] = useState();

  function confirm() {

    const newsForm = { 

      ...form,
      content: JSON.stringify( content )

    }
   
    handleConfirm( newsForm );

  }

  return (

    <Fragment>

      <h2>{ form?.url ? 'Редактировать' : 'Добавить' } новость</h2>
      
      <br/>

      <Textfield

        title = "Название статьи:"
        value = { form.name }
        set = { n => setFormField("name", n ) }

      />

      <Filefield
        
        title = "Обложка статьи:"
        value = { form.photo }
        set = { n => setFormField("photo", n ) }
        type = "image"
      
      />

      <Filefield

        title = "Большая картинка:"
        value = { form.image_big }
        set = { n => setFormField("image_big", n ) }
        type = "image"

      />

      <Editor 
      
        title = "Текст статьи:"
        content = { form?.content }
        set = { setContent }
      
      />

      <ActionButtons

        confirm = { confirm }
        remove = { handleRemove }
        cancel = { handleCancel }

      />
      
    </Fragment>

  );

}

export default NewsForm;