import axios from "axios";

export const http = axios.create({});

http.interceptors.request.use(

  (config) => config,
  (err) => Promise.reject(err)

);

http.interceptors.response.use(

  (data) => data,

  (error) => {

    if (error.response?.status === 401) {

      window.location.href = '/'; //НА СТРАНИЦУ ЛОГИНА

    }

    return Promise.reject(error);

  }

);