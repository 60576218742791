const COMPONENT_TYPE_NAME = {
  
  'av-type-id':	                'Антивирус',
  'cpu-type-id':	              'Процессор',
  'gpu-type-id':	              'Видеокарта',
  'memory-type-id':	            'Жёсткий диск',
  'network-card-type-id':	      'Сетевая карта',
  'onec-type-id':	              '1C',
  'os-type-id':	                'Операционная система',
  'raid-controller-type-id':	  'RAID-контроллер',
  'ram-type-id':	              'Оперативная память',
  'warranty-type-id':	          'Гарантия',
  'other-components-type-id':	  'Другие компоненты',
  'other-controllers-type-id':	'Другие контроллеры',
  
}

export default COMPONENT_TYPE_NAME;