function objectOfGetParamsFromUrl() {

  const url = new URL(window.location.href);
  const params = url.searchParams;
  const allGetParams = {};

  for (const [key, value] of params) {

    allGetParams[key] = value;

  }

  return allGetParams;

}

export default objectOfGetParamsFromUrl;